"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContentRect_1 = require("./ContentRect");
var ResizeObserverEntry = /** @class */function () {
  function ResizeObserverEntry(target) {
    this.target = target;
    this.contentRect = ContentRect_1.ContentRect(target);
  }
  return ResizeObserverEntry;
}();
exports.ResizeObserverEntry = ResizeObserverEntry;
