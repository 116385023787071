"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContentRect_1 = require("./ContentRect");
var ResizeObservation = /** @class */function () {
  function ResizeObservation(target) {
    this.target = target;
    this.$$broadcastWidth = this.$$broadcastHeight = 0;
  }
  Object.defineProperty(ResizeObservation.prototype, "broadcastWidth", {
    get: function () {
      return this.$$broadcastWidth;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(ResizeObservation.prototype, "broadcastHeight", {
    get: function () {
      return this.$$broadcastHeight;
    },
    enumerable: true,
    configurable: true
  });
  ResizeObservation.prototype.isActive = function () {
    var cr = ContentRect_1.ContentRect(this.target);
    return !!cr && (cr.width !== this.broadcastWidth || cr.height !== this.broadcastHeight);
  };
  return ResizeObservation;
}();
exports.ResizeObservation = ResizeObservation;
