"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContentRect = function (target) {
  if ('getBBox' in target) {
    var box = target.getBBox();
    return Object.freeze({
      height: box.height,
      left: 0,
      top: 0,
      width: box.width
    });
  } else {
    // if (target instanceof HTMLElement) { // also includes all other non-SVGGraphicsElements
    var styles = window.getComputedStyle(target);
    return Object.freeze({
      height: parseFloat(styles.height || '0'),
      left: parseFloat(styles.paddingLeft || '0'),
      top: parseFloat(styles.paddingTop || '0'),
      width: parseFloat(styles.width || '0')
    });
  }
};
exports.ContentRect = ContentRect;
